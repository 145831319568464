import React from "react";
import { withStyles } from "@material-ui/core";

const slogans = [
    "大年三十放炮那天晚上都不回家",
    "超喜欢这里的",
    "个个都是人才，说话又好听",
    "在家很无聊，都没有友仔玩",
    "这辈子是不可能打工的",
    "生意又不会做",
];

const styles = (_theme) => ({
    watermark: {
        fontSize: "4em",
        margin: "80px 0",
        "&:nth-child(even)": {
            marginLeft: "50%",
        },
    },
    sloganWrapper: {
        zIndex: -999,
        position: "fixed",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
    },
    sloganContainer: {
        whiteSpace: "nowrap",
        rotate: "-45deg",
        display: "flex",
        flexDirection: "column",
    },
    staticImg: {
        zIndex: -998,
        position: "fixed",
        left: "50%",
        bottom: 0,
        transform: "translate(-50%, 0)",
    },
});

const SloganComponent = (props) => {
    const { classes } = props;
    return (
        <>
            <div className={classes.sloganWrapper}>
                <div className={classes.sloganContainer}>
                    {slogans.map((slogan, index) => (
                        <h1 className={classes.watermark} key={slogan}>
                            {slogan}
                        </h1>
                    ))}
                </div>
            </div>
            <div className={classes.staticImg}>
                <img src={"/static/img/lqz.jpg"} />
            </div>
        </>
    );
};

const Slogan = withStyles(styles)(SloganComponent);

export default Slogan;
